import { LocaleMessages } from 'vue-i18n';

const locales: LocaleMessages = {
  'pt-br': {
    contacts: {
      newRegister: 'Cadastrar Contatos',
      companySelectLabel: 'Selecione uma empresa para vincular ao contato',
      companySelect: 'Selecione uma empresa',
      contactsTable: {
        contact: 'Contato',
        name: 'Nome',
        job: 'Cargo',
        company: 'Empresa',
        email: 'E-mail',
        phoneNumber: 'Telefone',
        responsibilities: 'Áreas de Responsabilidade',
      },
      contactForm: {
        fields: {
          name: {
            label: 'Nome',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Sobrenome',
            placeholder: 'Ex: Silva',
          },
          cellphone: {
            label: 'Telefone',
            placeholder: 'Ex: (99) 99999-9999',
          },
          mail: {
            label: 'E-mail',
            placeholder: 'Ex: nome@suaempresa.com',
          },
          occupation: {
            label: 'Cargo',
            placeholder: 'Ex: Analista Fiscal',
          },
          company: {
            label: 'Vincular empresa ao contato',
            placeholder: 'Selecione uma empresa',
          },
          crmIntegration: {
            label: 'Integração com CRM',
            placeholder: 'Selecione',
          },
          responsibilities: {
            label: 'Áreas de responsabilidade',
            placeholder: 'Selecione',
          },
        },
      },
      contactImport: {
        importMessage: 'Para importar contatos, selecione um perfil, baixe o template padrão correspondente, preencha-o e arraste para o campo abaixo',
        dropzone: 'Arraste o template aqui ou clique para selecioná-lo.',
        dropzoneEmpty: 'Nenhum template adicionado para importação',
      },
      contactFormModal: {
        tabRegisterContact: 'Cadastrar contato',
        titleRegister: 'Cadastrar Contatos',
        titleUpdate: 'Atualizar Contato',
      },
      list: {
        occupations: {
          msgError: 'Não foi obter as opções para ocupações',
        },
        integration: {
          msgError: 'Não foi obter as opções para integrações',
        },
        establishment: {
          msgError: 'Não foi obter as opções para empresas',
        },
      },
      actions: {
        findContact: {
          msgError: 'Incapaz de buscar contatos',
          msgWarning: 'Uma empresa deve ser selecionada para pesquisar seus contatos',
        },
        save: {
          msgSuccess: 'Contato registrado com sucesso',
          msgError: 'Não foi possível registrar o contato',
        },
        update: {
          msgSuccess: 'Dados de contato atualizados com sucesso',
          msgError: 'Incapaz de atualizar os dados do contato',
        },
      },
    },
  },
  en: {
    contacts: {
      newRegister: 'Register Contacts',
      companySelectLabel: 'Seleccione una empresa para vincular al contacto',
      companySelect: 'Seleccionar una empresa',
      contactsTable: {
        contact: 'Contact',
        name: 'Name',
        job: 'Position',
        company: 'Company',
        email: 'Email',
        phoneNumber: 'Phone',
        responsibilities: 'Areas of Responsibility',
      },
      contactForm: {
        fields: {
          name: {
            label: 'Name',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Last name',
            placeholder: 'Ex: Silva',
          },
          cellphone: {
            label: 'Phone',
            placeholder: 'Ex: (99) 99999-9999',
          },
          mail: {
            label: 'Email',
            placeholder: 'Ex: name@yourcompany.com',
          },
          occupation: {
            label: 'Position',
            placeholder: 'Ex: Tax Analyst',
          },
          company: {
            label: 'Link company to contact',
            placeholder: 'Select a company',
          },
          crmIntegration: {
            label: 'Integration with CRM',
            placeholder: 'Select',
          },
          responsibilities: {
            label: 'Areas of responsibility',
            placeholder: 'Select',
          },
        },
      },
      contactImport: {
        importMessage: 'To import contacts, select a profile, download the corresponding default template, fill it and drag to the field below',
        dropzone: 'Drag the template here or click to select it.',
        dropzoneEmpty: 'No templates added for import',
      },
      contactFormModal: {
        tabRegisterContact: 'Register Contact',
        titleRegister: 'Create Contacts',
        titleUpdate: 'Update Contact',
      },
      list: {
        occupations: {
          msgError: 'Could not get options for occupations',
        },
        integration: {
          msgError: 'Could not get options for integrations',
        },
        establishment: {
          msgError: 'Could not get options for companies',
        },
      },
      actions: {
        findContact: {
          msgError: 'Unable to fetch contacts',
          msgWarning: 'A company must be selected to search its contacts',
        },
        save: {
          msgSuccess: 'Contact registered successfully',
          msgError: 'Unable to register contact',
        },
        update: {
          msgSuccess: 'Contact data updated successfully',
          msgError: 'Unable to update contact data',
        },
      },
    },
  },
  es: {
    contacts: {
      newRegister: 'Registrar contactos',
      companySelectLabel: 'Seleccione una empresa para vincular al contacto',
      companySelect: 'Seleccionar una empresa',
      contactTable: {
        contact: 'Contacto',
        name: 'Nombre',
        job: 'Puesto de trabajo',
        company: 'Empresa',
        email: 'Correo electrónico',
        phoneNumber: 'Teléfono',
        responsabilidades: 'Áreas de responsabilidad',
      },
      contactForm: {
        fields: {
          name: {
            label: 'Nombre',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Apellido',
            placeholder: 'Ex: Silva',
          },
          cellphone: {
            label: 'Teléfono',
            placeholder: 'Ex: (99) 99999-9999',
          },
          mail: {
            label: 'Correo electrónico',
            placeholder: 'Ejemplo: nombre@suempresa.com',
          },
          occupation: {
            label: 'Posición',
            placeholder: 'Ex: Analista fiscal',
          },
          company: {
            label: 'Vincular empresa a contacto',
            placeholder: 'Seleccionar una empresa',
          },
          crmIntegration: {
            label: 'Integración con CRM',
            placeholder: 'Seleccionar',
          },
          responsibilities: {
            label: 'Áreas de responsabilidad',
            placeholder: 'Seleccionar',
          },
        },
      },
      contactImport: {
        importMessage: 'Para importar contactos, seleccione un perfil, descargue la plantilla predeterminada correspondiente, rellénela y arrástrela al campo de abajo',
        dropzone: 'Arrastre la plantilla aquí o haga clic para seleccionarla.',
        dropzoneEmpty: 'No se agregaron plantillas para importar',
      },
      contactFormModal: {
        tabRegisterContact: 'Registrar contacto',
        titleRegister: 'Registrar contactos',
        titleUpdate: 'Actualizar contacto',
      },
      list: {
        occupations: {
          msgError: 'No se pudieron obtener opciones para las ocupaciones',
        },
        integration: {
          msgError: 'No estaba obteniendo las opciones para integraciones',
        },
        establishment: {
          msgError: 'No estaba obteniendo las opciones para las empresas',
        },
      },
      actions: {
        findContact: {
          msgError: 'No se pueden recuperar los contactos',
          msgWarning: 'Se debe seleccionar una empresa para buscar sus contactos',
        },
        save: {
          msgSuccess: 'Contacto registrado correctamente',
          msgError: 'No se pudo registrar el contacto',
        },
        update: {
          msgSuccess: 'Datos de contacto actualizados correctamente',
          msgError: 'No se pueden actualizar los datos de contacto',
        },
      },
    },
  },
};

export default locales;
