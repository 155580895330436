
import mixins from 'vue-typed-mixins';
import { Establishment } from '@roit-intern/roit-company-interfaces';
import NotificationMixin from '@/mixins/NotificationMixin';
import locales from '../locales';

export default mixins(NotificationMixin).extend({
  name: 'ContactHeaderSection',
  i18n: {
    messages: locales,
  },

  data: () => ({
    selected: {} as Establishment,
  }),
  methods: {

    registerContacts() {
      this.$emit('contacts:register-contacts', true);
    },

    searchContacts(search: string) {
      const { length } = search.toString().trim();

      if (length === 0 || length > 1) {
        this.$emit('contacts:search', search);
      }
    },
  },
});
