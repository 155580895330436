import { RoitApiResponse } from '@/types';
import { Contact, ManageContact } from '@/resources/contacts/types';
import axios, { CancelTokenSource } from '@/common/http';

class ContactService {
    private service: typeof axios = axios;

    private cancelTS?: CancelTokenSource;

    private baseURL = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/contact`;

  private companyCoreRebornURL = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1`;

  private cancelToken(): CancelTokenSource {
    if (this.cancelTS) {
      this.cancelTS.cancel(ContactService.name);
    }

    return this.service.CancelToken.source();
  }

  public createContact(payload: ManageContact) {
    const newContact: ManageContact = payload;
    return this.service.post(this.baseURL, newContact);
  }

  public updateContact(contactId?: string, payload?: ManageContact) {
    return this.service.patch(`${this.baseURL}/${contactId}`, payload);
  }

  public importContacts(file: string) {
    return this.service.post(`${this.baseURL}/import`, { file });
  }

  // eslint-disable-next-line
    public async findContactByCnpj(cnpj: string, search?: string): Promise<RoitApiResponse<Contact[]>> {
    let baseUrl = `${this.baseURL}/${cnpj}`;

    if (search) {
      baseUrl = baseUrl.concat(`?search=${encodeURIComponent(search)}`);
    }

    this.cancelTS = this.cancelToken();
    const ret = await this.service.get(baseUrl, {
      cancelToken: this.cancelTS.token,
    });
    return ret.data;
  }

  public getOccupations() {
    const baseUrl = `${this.companyCoreRebornURL}/employee/occupations`;
    return this.service.get(baseUrl);
  }

  public async findByMasterId(masterId: string): Promise<RoitApiResponse<Contact[]>> {
    return (await this.service.get(`${this.baseURL}/all-by-master/${masterId}`)).data;
  }
}

export default new ContactService();
