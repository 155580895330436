
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import { Establishment } from '@roit-intern/roit-company-interfaces';
import NotificationMixin from '@/mixins/NotificationMixin';
import ContactsTable from '@/resources/contacts/components/ContactsTable.vue';
import ContactFormModal from '@/resources/contacts/components/ContactFormModal.vue';
import ContactService from '@/common/services/ContactService';
import { Contact, ManageContact } from '../types';
import locales from '../locales';

export default mixins(NotificationMixin).extend({
  name: 'Contacts',
  i18n: {
    messages: locales,
  },
  props: {
    establishment: {
      type: Object as () => Establishment,
    },
    registerContact: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
  },
  components: {
    ContactsTable,
    ContactFormModal,
  },
  data: () => ({
    establishmentSelected: {} as Establishment,
    loadingTable: true as boolean,
    deselectAllTable: false as boolean,
    contacts: [] as Array<Contact>,
    contact: {} as Contact,
  }),

  computed: {
    displayModal: {
      get() {
        return this.registerContact;
      },
      set(val: boolean) {
        this.$emit('update:registerContact', val);
      },
    },
  },

  async mounted() {
    await this.getContactsByMasterId();
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    async saveContact(contact: ManageContact): Promise<void> {
      try {
        this.handleLoading(true);

        await ContactService.createContact(contact);

        this.displayModal = false;

        this.successToast({
          text: this.$t('contacts.actions.save.msgSuccess') as string,
        });

        if (Object.keys(this.establishment).length) {
          await this.getContactsByMasterId();
        }
      } catch (e) {
        this.errorToast({
          text: this.$t('contacts.actions.save.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
    async updateContact(contact: ManageContact): Promise<void> {
      try {
        this.handleLoading(true);

        await ContactService.updateContact(contact.contactId, contact);

        this.displayModal = false;

        this.successToast({
          text: this.$t('contacts.actions.update.msgSuccess') as string,
        });

        await this.getContactsByMasterId();
      } catch (e) {
        this.errorToast({
          text: this.$t('contacts.actions.update.msgError') as string,
        });
      } finally {
        this.handleLoading(false);
      }
    },
    importContacts(contacts: ManageContact[]) {
      console.log('importContact contacts', contacts);
    },
    async getContactsByMasterId(): Promise<void> {
      try {
        this.loadingTable = true;

        const { masterId } = this.$store.state.auth.userCompany;

        this.contacts = (await ContactService.findByMasterId(masterId)).data as Array<Contact>;
      } catch (e) {
        this.errorToast({
          text: this.$t('users.index.actions.findUsers.msgError') as string,
        });
      } finally {
        this.loadingTable = false;
      }
    },
  },
});
