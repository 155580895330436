var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("contacts-table", {
        attrs: {
          rows: _vm.contacts,
          loading: _vm.loadingTable,
          "deselect-all": _vm.deselectAllTable,
        },
        on: {
          "contact:update": function ($event) {
            _vm.contact = $event
            _vm.displayModal = true
          },
        },
      }),
      _c("contact-form-modal", {
        attrs: { control: _vm.displayModal, contact: _vm.contact },
        on: {
          "update:control": function ($event) {
            _vm.displayModal = $event
          },
          "contact:register": function ($event) {
            return _vm.saveContact($event)
          },
          "contact:update": function ($event) {
            return _vm.updateContact($event)
          },
          "contact:imported": function ($event) {
            return _vm.importContacts($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }