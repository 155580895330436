var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("contact-header-section", {
        on: {
          "contacts:selected": function ($event) {
            return _vm.getContactsByEstablishment($event)
          },
          "contacts:register-contacts": _vm.showModal,
          "contacts:search": function ($event) {
            return _vm.searchContact($event)
          },
        },
      }),
      _c("contact-content-section", {
        attrs: {
          establishment: _vm.establishmentSelected,
          "register-contact": _vm.displayModal,
          search: _vm.search,
        },
        on: {
          "update:registerContact": function ($event) {
            _vm.displayModal = $event
          },
          "update:register-contact": function ($event) {
            _vm.displayModal = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }