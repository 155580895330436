var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "py-4" }, [
    _c("div", { staticClass: "p-field p-grid" }, [
      _c("div", { staticClass: "p-col-9" }, [
        _c("div", { staticClass: "flex justify-between" }, [
          _c(
            "div",
            [
              _c(
                "Button",
                {
                  ref: "btnregisterContacts",
                  staticClass: "p-button-raised",
                  on: {
                    click: function ($event) {
                      return _vm.registerContacts()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "pi pi-user-plus" }),
                  _c("span", { staticClass: "mx-2" }, [
                    _vm._v(_vm._s(_vm.$t("contacts.newRegister"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "min-w-300" }, [
            _c(
              "span",
              { staticClass: "p-input-icon-left w-100" },
              [
                _c("i", { staticClass: "icon-r-magnifier-tool grey100" }),
                _c("InputText", {
                  ref: "inputSearch",
                  staticClass: "w-100 no-box",
                  attrs: { placeholder: _vm.$t("search") },
                  on: {
                    input: function ($event) {
                      return _vm.searchContacts($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }