
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import {
  VForm,
} from '@/types';

import numberRemoveFormat from '@/common/filters/NumberRemoveFormat';
import ContactForm from './ContactForm.vue';
import ContactImport from './ContactImport.vue';
import {
  ManageContact,
} from '../types';
import locales from '../locales';

export default mixins(NotificationMixin).extend({
  name: 'ContactFormModal',

  i18n: {
    messages: locales,
  },

  components: {
    ContactForm,
    ContactImport,
  },

  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
    contact: {
      type: Object as () => ManageContact,
      required: false,
      default: () => ({}),
    } as PropOptions,
  },

  data: () => ({
    currentTab: 'main',
    contactForm: null as VForm | null,
    contactData: {} as ManageContact,
  }),

  watch: {
    contact(contact): void {
      this.contactData = contact;
    },
  },

  async mounted() {
    this.contactData = this.contact;
  },

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
    isUpdated(): boolean {
      return this.contact?.id !== undefined;
    },
    titleHeader(): string {
      return (!this.isUpdated ? this.$t('contacts.contactFormModal.titleRegister') : this.$t('contacts.contactFormModal.titleUpdate')) as string;
    },
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    registerContact(): void {
      if (this.contactForm) {
        const form = this.contactForm as VForm;

        form.validate().then((valid: boolean) => {
          if (!valid || !this.contactForm) return;
          const { masterId } = this.$store.state.auth.userCompany;

          const payload: ManageContact = {
            ...this.contactData,
          };

          payload.phone = numberRemoveFormat(payload.phone);
          payload.masterId = masterId;
          this.$emit('contact:register', payload);
        });
      }
    },

    updateContact(): void {
      const form: any = this.$refs.form as VForm;

      form.validate().then((valid: boolean) => {
        if (!valid) return;

        const payload = {
          email: this.contactData.email,
        };

        this.$emit('contact:update', payload);
      });
    },
    setTab(value: any) {
      if (value) {
        this.currentTab = value?.index === 0 ? 'main' : 'import-contact';
      }
    },
  },
});
