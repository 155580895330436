
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import NotificationMixin from '@/mixins/NotificationMixin';
import { IntegrationSystem, SelectOption } from '@/types';

import integrationService from '@/common/services/IntegrationService';
import EstablishmentService from '@/common/services/EstablishmentService';
import ContactService from '@/common/services/ContactService';
import { ManageContact } from '../types';
import locales from '../locales';

const propFields: ManageContact = {
  firstName: '',
  lastName: '',
  masterId: '',
  phone: '',
  email: '',
  job: '',
  companies: [],
  integrationCRM: [],
  responsabilities: [],
  active: true,
};

export default mixins(NotificationMixin).extend({
  name: 'ContactForm',

  i18n: {
    messages: locales,
  },

  props: {
    value: {
      type: Object as () => ManageContact,
      required: false,
      default: () => ({}),
    } as PropOptions,
  },

  data: () => ({
    fields: { ...propFields },
    establishments: [] as SelectOption[],
    responsibilities: [] as SelectOption[],
    integrationCRM: [] as SelectOption[],
    occupations: [] as SelectOption[],
  }),

  computed: {
    ...mapGetters({
      token: 'auth/token',
      userLoggedIn: 'auth/userLoggedIn',
    }),
    isDisabled(): boolean {
      return this.value?.id !== undefined;
    },
    isUpdated(): boolean {
      return this.isDisabled;
    },
  },

  watch: {
    fields: {
      deep: true,
      handler(contact: ManageContact) {
        this.$emit('input', { ...this.value, ...contact });
      },
    },
  },

  async mounted() {
    await this.handleLoadData();
    this.contactFieldsAdapter();
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    contactFieldsAdapter() {
      const { value: contact } = this;
      if (this.isUpdated) {
        this.fields = {
          ...contact,
          firstName: contact.firstName?.trim(),
          fullName: `${contact.firstName} ${contact.lastName}`.trim(),
        };
      }
    },

    async getResponsibilities(): Promise<any> {
      this.responsibilities = [
        {
          optionLabel: 'Contábil',
          name: 'Contábil',
        },
        {
          optionLabel: 'Fiscal',
          name: 'Fiscal',
        },
        {
          optionLabel: 'Financeiro',
          name: 'Finanças',
        },
        {
          optionLabel: 'Folha',
          name: 'Recursos Humanos',
        },
      ] as SelectOption[];
    },

    async getEstablishments(): Promise<void> {
      try {
        this.establishments = await EstablishmentService.selectOptions();
      } catch (e) {
        this.errorToast({
          text: this.$t('contacts.list.establishment.msgError') as string,
        });
      }
    },

    async getIntegrationCRM(): Promise<void> {
      try {
        // eslint-disable-next-line max-len
        this.integrationCRM = (
          await integrationService.getIntegrationsCRM()
        ).data.data.map((integration: IntegrationSystem) => {
          // eslint-disable-next-line max-len
          // this.integrationCRM = (await integrationService.getIntegrations()).data.data.map((integration: IntegrationSystem) => {
          const name = integration.system;
          return {
            optionLabel: `${name}`,
            name: `${name}`,
          };
        });
      } catch (e) {
        this.errorToast({
          text: this.$t('contacts.list.integration.msgError') as string,
        });
      }
    },

    async getOccupations(): Promise<void> {
      try {
        const { data: response } = await ContactService.getOccupations();
        this.occupations = response.data.map(
          (item: { label: string; value: string }) => ({
            optionLabel: item.label,
            name: item.value,
          }),
        );
      } catch (e) {
        this.errorToast({
          text: this.$t('contacts.list.occupations.msgError') as string,
        });
      }
    },

    async handleLoadData() {
      if (this.$refs.form) {
        this.$emit('form', this.$refs.form);
      }
      this.handleLoading(true);
      if (!this.isUpdated) {
        this.getEstablishments();
        this.getOccupations();
      }
      this.getIntegrationCRM();
      this.getResponsibilities();
      this.handleLoading(false);
    },
  },
});
