
import Vue from 'vue';
import Table from '@/common/components/table/Table.vue';
import PhoneFormat from '@/common/filters/PhoneFormat';
import { HeaderCustomTable } from '@/types';
import { ManageContact } from '../types';
import locales from '../locales';

export default Vue.extend({
  name: 'ContactsTable',
  i18n: {
    messages: locales,
  },
  components: {
    CustomTable: Table,
  },

  filters: {
    PhoneFormat,
  },

  props: {
    rows: {
      type: Array,
      required: true,
    },
    // TEST

    loading: {
      type: Boolean as () => boolean,
      default: false,
    },

    deselectAll: {
      type: Boolean as () => boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      allItemsSelected: false as boolean,
      selectedItems: [] as Array<string | null>,
      visibleDeleteAll: false as boolean,
      header: {
        alias: [
          'checkbox',
          this.$t('contacts.contactsTable.name'),
          this.$t('contacts.contactsTable.phoneNumber'),
          this.$t('contacts.contactsTable.email'),
          this.$t('contacts.contactsTable.job'),
        ],
        columnsToShow: [
          'checkbox',
          'name',
          'phoneNumber',
          'email',
          'job',
        ],
      } as HeaderCustomTable,
    };
  },

  computed: {
    rowsData(): Array<ManageContact> {
      return this.rows as Array<ManageContact>;
    },
  },

  watch: {
    allItemsSelected(value: boolean): void {
      if (value) {
        this.selectedItems = this.rowsData.map((contact: ManageContact) => contact.email);
      } else {
        this.selectedItems = [];
      }
    },
    selectedItems(value: Array<string>): void {
      this.visibleDeleteAll = !!value.length;
    },

    deselectAll(value: boolean): void {
      if (value) {
        this.clearSelectedItems();
        this.$emit('contact:deselect-all', false);
      }
    },
  },

  methods: {
    clearSelectedItems(): void {
      this.allItemsSelected = false;
      this.selectedItems = [];
    },

    editContact(contact: ManageContact): void {
      this.$emit('contact:update', contact);
    },

    disableContacts(contact?: ManageContact): void {
      this.$emit('contact:disable', contact ? [contact.email] : this.selectedItems);
    },
  },

});
