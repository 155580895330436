var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    ref: "contactTable",
    attrs: {
      "columns-data": _vm.header,
      data: _vm.rowsData,
      loading: _vm.loading,
      paginate: _vm.rowsData && _vm.rowsData.length > 0,
      "row-length": _vm.rowsData.length,
      "delete-all": _vm.visibleDeleteAll,
    },
    on: {
      "delete-all:clear": function ($event) {
        return _vm.clearSelectedItems()
      },
      "delete-all:delete": function ($event) {
        return _vm.disableContacts()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function ({ column }) {
          return [
            column === "checkbox"
              ? _c("th", { staticClass: "pl-0 text-center" }, [
                  _c(
                    "div",
                    { staticClass: "d-inline-flex px-2" },
                    [
                      _c("Checkbox", {
                        ref: "allItemsSelected",
                        attrs: { binary: true },
                        model: {
                          value: _vm.allItemsSelected,
                          callback: function ($$v) {
                            _vm.allItemsSelected = $$v
                          },
                          expression: "allItemsSelected",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "columns",
        fn: function ({ item, column }) {
          return [
            column === "name"
              ? _c("td", [
                  _vm._v(
                    " " + _vm._s(`${item.firstName} ${item.lastName}`) + " "
                  ),
                ])
              : _vm._e(),
            column === "phoneNumber"
              ? _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm._f("PhoneFormat")(item.phoneNumber)) + " "
                  ),
                ])
              : _vm._e(),
            column === "job"
              ? _c("td", [_vm._v(" " + _vm._s(item.job) + " ")])
              : _vm._e(),
            column === "email"
              ? _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "p-link",
                      attrs: { href: `mailto:${item.email}`, target: "_blank" },
                    },
                    [_vm._v(" " + _vm._s(item.email) + " ")]
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "actions",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t("edit"),
                      expression: "$t('edit')",
                      modifiers: { top: true },
                    },
                  ],
                  staticClass: "action",
                  attrs: { id: "edit" },
                  on: {
                    click: function ($event) {
                      return _vm.editContact(item)
                    },
                  },
                },
                [_c("i", { staticClass: "icon-r-edit" })]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t("disableContact"),
                      expression: "$t('disableContact')",
                      modifiers: { top: true },
                    },
                  ],
                  staticClass: "action",
                  attrs: { id: "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.disableContacts(item)
                    },
                  },
                },
                [_c("i", { staticClass: "icon-r-user-remove color__red" })]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }