
import mixins from 'vue-typed-mixins';
import ContactHeaderSection from '@/resources/contacts/sections/HeaderSection.vue';
import ContactContentSection from '@/resources/contacts/sections/ContentSection.vue';
import { Company } from '@/types';

export default mixins().extend({
  name: 'Contacts',
  components: {
    ContactHeaderSection,
    ContactContentSection,
  },
  data: () => ({
    establishmentSelected: {} as Company,
    search: '' as string | undefined,
    displayModal: false,
  }),

  methods: {

    showModal(): void {
      this.displayModal = true;
    },

    getContactsByEstablishment(establishment: Company) {
      this.establishmentSelected = establishment;
    },

    searchContact(search?: string) {
      this.search = search?.trim();
    },
  },
});
